import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// Helper function to generate mock data (just for demonstration purposes)
const generateTeamData = (id) => {
  const teamNames = ['Falcons', 'Tigers', 'Wolves', 'Sharks', 'Eagles'];

  return {
    teamName: teamNames[id % teamNames.length],
  };
};

const NextDivision = (props) => {
    let { ids, isMobile, handleInvite, handleCreateDivision, onSelectedTeamsChange } = props;
    ids = ids.filter(id => id !== 0 && id !== "0");

    const [selectedExtraTeam, setSelectedExtraTeam] = useState(null); // Track the selected team from extra teams
    const [extraTeams, setExtraTeams] = useState(ids.slice(8)); // Get the extra teams
    const [searchTerm, setSearchTerm] = useState(""); // State to store search input
    const [showMore, setShowMore] = useState(false); // State to control showing more teams
    const [filteredTeams, setFilteredTeams] = useState(extraTeams); // Filtered teams based on search
    const [mainTeams, setMainTeams] = useState(ids.slice(0, 8)); // Limit the main table to 8 rows

    // Calculate how many empty rows we need to add to make it 8 rows if there are fewer than 8 teams
    const emptyRowsCount = 8 - mainTeams.length;

    // Handle selecting an extra team to potentially replace a main team
    const handleExtraTeamSelection = (id) => {
        setSelectedExtraTeam(id);
    };

    // Replace a main team with the selected extra team
    const handleReplaceTeam = (mainTeamId) => {
        if (selectedExtraTeam) {
            const updatedMainTeams = mainTeams.map(team => 
                team === mainTeamId ? selectedExtraTeam : team
            );
            setMainTeams(updatedMainTeams);
            onSelectedTeamsChange(updatedMainTeams);  // Pass the updated teams

            setExtraTeams(prevExtraTeams => [...prevExtraTeams.filter(team => team !== selectedExtraTeam), mainTeamId]);
            setSelectedExtraTeam(null);
        }
    };

    // Handle search term updates
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    // Filter the teams based on search term
    useEffect(() => {
        setFilteredTeams(
            extraTeams.filter(team => {
                const { teamName } = generateTeamData(team);
                return teamName.toLowerCase().includes(searchTerm) || team.toString().includes(searchTerm);
            })
        );
    }, [searchTerm, extraTeams]);

    useEffect(() => {
        onSelectedTeamsChange(mainTeams);  // Pass the updated selected teams to the parent
    }, [mainTeams, onSelectedTeamsChange]);

    const handleCreateDivisionClick = () => {
        handleCreateDivision(mainTeams);  // Pass the selected teams when creating the division
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="box-shadow-simple justify-content-center" 
            style={{padding:"2%", backgroundColor: '#fff', margin: "auto", marginBottom: "2%", borderRadius: "10px"}}
        >
            <Row className='justify-content-center' style={{paddingBottom: "2%"}}>
                <div className="sub-title-mobile"> 
                    Next Division
                </div>
            </Row>
            <Row className='justify-content-center' style={{paddingBottom: "2%"}}>
                <div style={{fontStyle: "italic"}}> 
                    {mainTeams.length < 8 ? `${8 - mainTeams.length} teams left to join` : ""}
                </div>
            </Row>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Team Name</th>
                        <th>Team Id</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {mainTeams.map((id, index) => {
                        const { teamName } = generateTeamData(id);
                        return (
                            <tr key={id}>
                                <td>{index + 1}</td>
                                <td>{teamName}</td>
                                <td>{id}</td>
                                <td>
                                    {selectedExtraTeam ? (
                                        <Button 
                                            variant="outline-danger"
                                            onClick={() => handleReplaceTeam(id)}
                                        >
                                            Replace
                                        </Button>
                                    ) : (
                                        <Button 
                                            variant= "primary"
                                            disabled={true}
                                        >
                                            Selected
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    {/* Add empty rows to make up for a total of 8 rows */}
                    {Array.from({ length: emptyRowsCount }).map((_, index) => (
                        <tr key={`empty-${index}`}>
                            <td colSpan="4" style={{ textAlign: 'center' }}>
                                <Button onClick={handleInvite} variant="outline-success">
                                    + Invite Player
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* Show Create Division button if there are 8 or more teams */}
                        {mainTeams.length === 8 && (
                <Row className="justify-content-center" style={{ paddingBottom: '2%' }}>
                    <button onClick={handleCreateDivisionClick} className='gradient-button'>
                        <div className="sub-title-mobile">
                        Create Division
                        </div>
                    </button>
                </Row>
            )}
            {/* Show extra teams if there are more than 8 */}
            {extraTeams.length > 0 && (
                <>
                    <Row className="justify-content-between align-items-center" style={{ paddingTop: '2%' }}>
                        <Col>
                            <h5>Other Teams:</h5>
                        </Col>
                        <Col xs={5}>
                            <Form.Control 
                                type="text"
                                placeholder="Search teams..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center" style={{ paddingTop: '2%' }}>
                        <Col>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Index</th>
                                        <th>Team Name</th>
                                        <th>Team Id</th>
                                        <th>Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTeams.slice(0, showMore ? filteredTeams.length : 3).map((id, index) => {
                                        const { teamName } = generateTeamData(id);
                                        return (
                                            <tr key={id}>
                                                <td>{index + 9}</td> {/* Adjust index for extra teams */}
                                                <td>{teamName}</td>
                                                <td>{id}</td>
                                                <td>
                                                    <Button 
                                                        variant={selectedExtraTeam === id ? "outline-success" : "outline-primary"}
                                                        onClick={() => handleExtraTeamSelection(id)}
                                                        disabled={selectedExtraTeam === id}  // Disable button if already selected
                                                    >
                                                        {selectedExtraTeam === id ? "Selected" : "Select"}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {filteredTeams.length > 3 && (
                                <Button variant="link" onClick={handleShowMore}>
                                    {showMore ? "Show Less" : "Show More"}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default NextDivision;
